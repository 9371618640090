import React, { Component } from "react";
import CreateAccountDetails from "../../components/Create Account Form/CreateAccountDetails";
import Navbar from "../../components/Navbar/Navbar";
import "./CreateAccountStyle.css";

export default class CreateAccount extends Component {
  render() {
    return (
      <>
        <Navbar />
        <div className="banner">
          <div className="pt-4 pt-md-10">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-md-5 col-lg-6 order-2 order-lg-2 order-md-2 order-sm-2 order-xl-2 order-xll-2 text-center">
                  <CreateAccountDetails/>
                </div>
                <div className="col-12 col-md-7 col-lg-6 order-1 order-lg-1 order-md-1 order-sm-1 order-xl-1">
                  <h3 className="display-4 text-center text-md-left">
                    <span className="text-primary">
                      Create a Testing Bank Account
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

import React from "react";
import "./LoadingButtonStyle.css"

function LoadingButton ({btnTitle, isLoading, onClick}) {
  return (
    <button
      type="submit"
      className="loading-btn"
      onClick={onClick}
    >
      {
        isLoading ? <i className="fas fa-spinner fa-spin loading-btn-gif"></i> : btnTitle
      }
    </button>
  );
}

export default LoadingButton;

import {React, useState, useEffect } from "react";
import "./EditAccountPopupStyle.css";
import LoadingButton from "../Loading button/LoadingButton";

function EditAccountPopup({ account, onPopup, message }) {
  let [responseGetAccount, setResponseGetAccount] = useState("");
  let [responseUpdateAccount, setResponseUpdateAccount] = useState("");
  let [accountData, setAccountData] = useState("");
  let [isResponseLoading, setIsResponseLoading] = useState(false);

  async function getAccount(account) {
    const hostname = window.location.hostname;
    await fetch(`https://${hostname}/ConnectHub/AccountManagement/Account/${account.fipId}/${account.accountRefNo}`, {
        method: "GET"
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        setResponseGetAccount(data);
        setAccountData(responseGetAccount);
      });
  }

  async function updateAccount(account, accountData) {
    const hostname = window.location.hostname;
    await fetch(`https://${hostname}/ConnectHub/AccountManagement/Account/${account.fipId}/${account.accountRefNo}`,{
        method: "PUT",
        body: accountData
      }
    )
      .then((response) => {
        setIsResponseLoading(false);
        return response.text();
      })
      .then((data) => {
        setResponseUpdateAccount(data);
      });
  }

  useEffect(() => {
    getAccount(account);
  }, [responseGetAccount]);

  const handleUpdateAccount = (account, accountData) => {
    setIsResponseLoading(true);
    updateAccount(account, accountData);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        backgroundColor: "rgba(0,0,0,0.6)",
      }}
      onClick={() => onPopup(false)}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          background: "white",
          padding: "25px",
          borderRadius: "10px",
          maxHeight: "85%",
          maxWidth: "70%"
        }}
      >
        <h5 style={{ color: "black" }}>{message}</h5>
        <div style={{ overflow: "auto", flex: "1" }}>
          <textarea
            className="custom-textarea"
            autoComplete="off"
            aria-autocomplete="list"
            aria-haspopup="true"
            defaultValue={responseGetAccount}
            style={{ padding: "10px" }}
            onChange={(e) => setAccountData(e.target.value)}
          />
        </div>
        <span style={{ fontSize: 14, color: "red" }}>Please put account data in xml format as per ReBIT specifications</span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span>
            <LoadingButton
              btnTitle="Save"
              isLoading={isResponseLoading}
              onClick={() => handleUpdateAccount(account, accountData)}
            />
          </span>
          <span>
            <button
              type="submit"
              className="cancel-btn"
              onClick={() => onPopup(false)}
            >
              Cancel
            </button>
          </span>
        </div>
        {responseUpdateAccount.includes("success") ? <span style={{ fontSize: 16, color: "green" }}>{responseUpdateAccount}</span>
        : <span style={{ fontSize: 16, color: "red" }}>{responseUpdateAccount}</span>}
      </div>
    </div>
  );
}

export default EditAccountPopup;

import React from "react";
import Logo from "../../assets/img/brand.png";
import "./NavbarStyle.css";


export default function Navbar() {
  return (
    <nav className="navbar navbar-light bg-light">
        <a className="navbar-brand-img" href="/">
          <img
            src={Logo}
            width={100}
            height={50}
            alt="Finvu"
          />
        </a>
      </nav>
  )
}
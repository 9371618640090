import React, { Component } from "react";
import Navbar from "../../components/Navbar/Navbar";
import ManageAccountsDetails from "../../components/Search Form/ManageAccountsDetails"
import "./ManageAccountsStyle.css";

export default class ManageAccounts extends Component {
  render() {

    return (
      <>
        <Navbar />
        <div className="banner">
          <div className="pt-4 pt-md-10">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 text-center">
                  <ManageAccountsDetails />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
